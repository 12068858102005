import React from "react"
import SEO from "../components/seo"
import styled from 'styled-components';

const Wrapper = styled.div`
  .page-not-found {
    background-color:darkgoldenrod;  
    height:50vh;
  }
`;

const NotFoundPage = () => (
  <React.Fragment>
    <SEO title="404: Not found" />
    <Wrapper>
      <div className="row page-not-found">
        <div className="col-sm-12 col-md-12 col-lg-12 main-title">
          <div className="row h-100 align-items-center">
            <div className="mx-auto">
                <div className="p-2">
                  <h1>404</h1>
                </div>
                <div className="p-2">
                  <p className="h1">Página não encontrada</p>
                </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  </React.Fragment>
)

export default NotFoundPage
